/*jshint globalstrict: true*/
/* jshint node: true */
"use strict";

const CHECK_IMAGE = 'https://mlc.learningstewards.org/wp-content/uploads/2018/03/transparent-check.png';
const X_IMAGE = 'https://mlc.learningstewards.org/wp-content/uploads/2018/03/transparent-x.png';
const TRY_AGAIN_IMAGE = 'https://mlc.learningstewards.org/wp-content/uploads/2018/05/tryagain250.png';


class PqQuizComponent {

    constructor(componentEl, options) {

        this.container = componentEl;
        this.player = null;
        this.playHandle = null;
        this.playerLocked = false;

        if (typeof options !== 'undefined') {
            this.options = options;
        } else {
            this.options = {};
        }

        this.GAME_STATUS_STARTED = 1;
        this.GAME_STATUS_ENDED = 2;
        this.gameStatus = this.GAME_STATUS_STARTED;

        this.countCorrect = 0;
        this.openedCorrect = 0;

        this.startPcued = false;
        this.gameMode = '';

        this.successLayer = null;

        this.word = '';
        this.pcueInfo = null;
        this.countCompleted = 0;
        this.autoPlayWord = false;

        this.playOnFinished = true;

        this.blinker = null;

        this.isFinishing = false;

        this.pcueInfoGroups = [];

        this.wordCorrectMessage = null;
        this.pqType = null;
        this.instructionSentence = null;
        this.wordImageUrl = null;
        this.playLetterCorrectMessage = true;
        this.includeSegments = false;

        this.adjustPadding();

        this.initialize();
    }

    /**
     * creates a padding to the left and right
     * @todo figure out where and when to insert this code and whether or not it's possible to do it from
     * the editor
     */
    adjustPadding() {

        if (!this.container.style.fontSize) {
            return;
        }

        // adjust padding for inside the word box and between the speaker and the first letter.
        let padding = Math.abs(parseInt(this.container.style.fontSize) / 5);
        this.container.style.padding = '0 ' + padding + 'vw';

        const hasSpeaker = this.container.hasAttribute('data-include-word-speaker');
        let speaker;

        if (hasSpeaker) {
            speaker = this.container.querySelector('.pq-quiz-speaker-cell');
            speaker.style.paddingRight = padding + 'vw';
        }

        // adjust for 2-letter or 3 letter words
        const cntLetters = this.container.querySelectorAll('.pq-quiz-answer-button').length;
        const minCharacters = 4;
        let extra = minCharacters - cntLetters;
        const wordRow = this.container.querySelector('.pq-quiz-word-row');
        const buttonsRow = this.container.querySelector('.pq-quiz-buttons-row');

        if( this.container.getAttribute('data-game-mode') === 'segmentation') {
            extra = 0;
        }

        if ((extra) > 0) {
            let padding = parseInt(this.container.style.fontSize);
            if (extra === 1) {
                padding = padding / 2;
            }

            const firstLetterCell = wordRow.querySelector('td:not(.pq-quiz-speaker-cell)');
            firstLetterCell.style.paddingLeft = padding + 'vw';

            const firstButton = buttonsRow.querySelector('td');
            firstButton.style.paddingLeft = padding + 'vw';

            const lastLetterCell = wordRow.querySelector('td:not(.pq-quiz-speaker-cell):last-child');
            lastLetterCell.style.paddingRight = padding + 'vw';

            const lastButton = buttonsRow.querySelector('td:last-child');
            lastButton.style.paddingRight = padding + 'vw';
        }
    }

    disablePlayOnFinish() {
        this.playOnFinished = false;
    }

    setPlayer(player) {
        this.player = player;
    }

    initialize() {

        this.word = this.container.textContent.trim().replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g,'');

        if (this.container.hasAttribute('data-start-pcued')){
            this.startPcued = true;
        }

        if(this.container.hasAttribute('data-game-mode')){
            this.gameMode = this.container.getAttribute('data-game-mode');
        } else if (this.startPcued) {
            this.gameMode = 'look';
        } else {
            this.gameMode = 'listen';
        }

        if(this.gameMode === 'look'){
            this.startPcued = true;
        }

        this.wordCorrectMessage = this.container.getAttribute('data-word-correct-message');

        if (this.container.hasAttribute('data-pq-type')){
            this.pqType = this.container.getAttribute('data-pq-type');
        }

        if (this.container.hasAttribute('data-instruction-sentence')){
            this.instructionSentence = this.container.getAttribute('data-instruction-sentence');
        }

        if (this.container.hasAttribute('data-word-image-url')){
            this.wordImageUrl = this.container.getAttribute('data-word-image-url');
        }

        if(this.gameMode === 'look'){
            this.startPcued = true;
        }

        if(this.container.hasAttribute('data-include-segments') && this.gameMode !== 'segmentation'){
            this.includeSegments = true;
        }

        if(this.container.hasAttribute('data-auto-play-word')){
            this.autoPlayWord = true;
        }

        this.countCorrect = this.container.getElementsByClassName('pq-quiz-correct').length;

        console.log('Correct letters count: ' + this.countCorrect);
        if (this.countCorrect === 1) {
            this.playLetterCorrectMessage = false;
        }

        // this.successLayer = this.container.querySelector('.pq-quiz-success-layer');

        let wordRow = this.container.querySelector('.pq-quiz-word-row');
        if (!wordRow) {
            console.log("Missing Word Row");
            return;
        }

        let listenCell = wordRow.querySelector('.pq-quiz-speaker-cell');

        if (this.startPcued){
            let lookCell = document.createElement('td');
            lookCell.classList.add('pq-quiz-speaker-cell');
            lookCell.verticalAlign = 'middle';
            lookCell.rowSpan = 2;

            let lookImg = document.createElement('img');
            lookImg.style.height = this.container.style.fontSize;
            lookImg.style.width = this.container.style.fontSize;
            lookImg.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/04/look64.png');

            lookCell.appendChild(lookImg);
            wordRow.insertBefore(lookCell, wordRow.firstChild);
        }

        // tag index of each button and letter
        let answerButtonCells = this.container.querySelectorAll('.pq-quiz-buttons-row td:not(.empty-segment-button-cell)');
        for (let i = 0; i < answerButtonCells.length; i++) {
            answerButtonCells[i].setAttribute('data-index', i.toString());
            let button = answerButtonCells[i].querySelector('.pq-quiz-answer-button');
            button.setAttribute('data-index', i.toString());
        }

        let letterCells = wordRow.querySelectorAll('td:not(.pq-quiz-speaker-cell):not(.segment-cell)');
        for (let i = 0; i < letterCells.length; i++) {
            let cell = letterCells[i];
            cell.setAttribute('data-index', i.toString());
        }

        this.getPcueInfo();

        this.listenToEvents();
    }


    /**
     * request pcue info via ajax
     */
    getPcueInfo() {
        let self = this;
        let request = new XMLHttpRequest();
        let ajaxUrl = '/wp-admin/admin-ajax.php?action=pcue_info&word=' + encodeURIComponent(this.word);

        request.onreadystatechange = function() {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.status === 200) {
                    self.pcueInfo = JSON.parse(this.responseText);
                    if (self.gameMode === 'segmentation'){
                        self.markCorrectSegmentButtons();
                        self.countCorrect = self.container.querySelectorAll('.pq-quiz-correct').length;
                    }

                    // process PQ group format
                    if (self.pcueInfo['group-format']) {
                        let groupInfo = self.pcueInfo['group-format'];
                        let keys = Object.keys(groupInfo);

                        for (let i = 0; i < keys.length; i++) {
                            let groupIndex = parseInt(keys[i]);

                            let group = {indices: [], style: groupInfo[groupIndex].style};
                            if (groupInfo[groupIndex].length > 0) {
                                for (let j = 0; j < groupInfo[groupIndex].length; j++) {
                                    group.indices.push(groupIndex + j);
                                }
                            }

                            self.pcueInfoGroups.push(group);
                        }
                    }

                    if (self.includeSegments){
                        self.addAllSegments();
                    }

                    self.addInstructionLayer();
                    self.showInstructionLayer();

                    if (self.startPcued) {
                        for (let i = 0; i < self.word.length; i++) {
                            self.applyPcue(i);
                        }
                    }
                }
            }
        };

        request.open("GET", ajaxUrl, true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        request.send();
    }

    getCountCompleted() {
        return this.countCompleted;
    }

    addAllSegments(){
        let curButtonIndex = 0;
        for(let i = 0; i < this.pcueInfo.pcuebreak.length; i++){
            let wordRow = this.container.querySelector('.pq-quiz-word-row');
            let buttonsRow = this.container.querySelector('.pq-quiz-buttons-row');
            let newSegmentCell = wordRow.insertCell(this.pcueInfo.pcuebreak[i] + i + 2);
            newSegmentCell.classList.add('segment-cell');

            let newEmptyBottomCell = buttonsRow.insertCell(this.pcueInfo.pcuebreak[i] + i + 1);
            newEmptyBottomCell.classList.add('empty-bottom-segment-cell');

            let segment = document.createElement('div');
            segment.classList.add('segment');
            segment.style.margin = '0';
            segment.style.padding = '0';
            segment.style.width = '0.75vw';
            segment.style.height = '0.75vw';
            segment.style.backgroundColor = 'black';
            segment.style.marginLeft = '0.5vw';
            segment.style.marginRight = '0.5vw';
            segment.innerHTML = '&nbsp;';
            newSegmentCell.appendChild(segment);
        }
    }

    listenToEvents() {
        let self = this;

        this.container.addEventListener('click', function(event) {
            if (event.target.classList && event.target.classList.contains('pq-quiz-answer-button') &&
                self.gameStatus !== self.GAME_STATUS_ENDED)
            {
                self.answer(event.target);
            }
            else {
                if (event.target.classList && event.target.classList.contains('pq-quiz-check-success')) {
                    self.stopPlay();
                    self.clearAndRestore();
                    self.reset();
                }

                // let checkMark = self.successLayer.querySelector('img');
                // if (checkMark && event.target === checkMark) {
                //     self.stopPlay();
                //     self.clearAndRestore();
                //     self.reset();
                // }
            }
        });

        if (this.container.hasAttribute('data-include-word-speaker')) {
            let speaker = this.container.querySelector('.pq-quiz-speaker-cell img');
            if (speaker && speaker.hasAttribute('data-word')) {
                speaker.addEventListener('click', function(event) {
                    if (!self.playerLocked) {
                        self.player.playText(speaker.getAttribute('data-word'));
                    }
                });
            }
        }
    }

    showSuccess() {
        // this.successLayer.style.visibility = 'visible';

        let newCell = this.container.querySelector('.pq-quiz-buttons-row td[data-index="0"]');
        this.oldFirstCellHtml = newCell.innerHTML;

        let lastIndex = 0;
        if(this.gameMode === 'segmentation'){
            lastIndex = this.word.length - 1;
        } else {
            lastIndex = this.word.length;
        }

        for (let i = 1; i < lastIndex; i++) {
            this.container.querySelector('.pq-quiz-buttons-row td[data-index="' + i + '"]').style.display = 'none';
        }

        let emptyCells = this.container.querySelectorAll('td');
        for (let i = 1; i < emptyCells.length; i++) {
            if (emptyCells[i].innerHTML === "&nbsp;"){
                emptyCells[i].innerHTML = "";
            }
        }

        let lastCell = 0;
        if(this.gameMode === 'segmentation'){
            lastCell = lastIndex * 2 -1;
        } else {
            lastCell = lastIndex;
        }


        newCell.setAttribute('colspan', lastCell.toString());

        let checkMark = document.createElement('img');
        checkMark.classList.add('pq-quiz-check-success');
        checkMark.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/03/transparent-check.png');
        checkMark.style.height = this.container.style.fontSize;

        newCell.innerHTML = checkMark.outerHTML;
    }

    hideSuccess() {
        // this.successLayer.style.visibility = 'hidden';

        let newCell = this.container.querySelector('.pq-quiz-buttons-row td[data-index="0"]');
        newCell.innerHTML = this.oldFirstCellHtml;
        newCell.removeAttribute('colspan');

        let lastIndex = 0;
        if(this.gameMode === 'segmentation'){
            lastIndex = this.word.length - 1;
        } else {
            lastIndex = this.word.length;
        }

        for (let i = 1; i < lastIndex; i++) {
            this.container.querySelector('.pq-quiz-buttons-row td[data-index="' + i + '"]').style.display = 'table-cell';
        }
    }

    reset() {
        let self = this;
        this.openedCorrect = 0;

        this.hideSuccess();

        if (this.gameMode === 'segmentation'){
            let letterCells = this.container.querySelectorAll('.pq-quiz-word-row td:not(.pq-quiz-speaker-cell)');

            Array.from(letterCells).forEach(function(cell) {
                if (cell.classList.contains('segment-cell')){
                    cell.innerHTML = '&nbsp;';
                } else {
                    cell.innerHTML = cell.textContent;
                }
            });

            let pqQuizAnswerButtons = this.container.getElementsByClassName('pq-quiz-answer-button');
            Array.from(pqQuizAnswerButtons).forEach(function(button) {
                button.checked = false;
                button.style.display = 'inline-block';
                button.style.borderColor = 'transparent transparent #dedede transparent';
            });

        } else {

            let segmentCells = self.container.querySelectorAll('.segment-cell');
            Array.from(segmentCells).forEach(function(cell) {
                cell.remove();
            });

            let emptyBottomCells = self.container.querySelectorAll('.empty-bottom-segment-cell');
            Array.from(emptyBottomCells).forEach(function(cell) {
                cell.remove();
            });

            if (this.pcueInfoGroups.length > 0) {
                this.pcueInfoGroups.forEach(function(group) {
                    let firstCell = self.container.querySelector('.pq-quiz-word-row td[data-index="' + group.indices[0] + '"]');
                    if (firstCell.hasAttribute('colspan')) {
                        firstCell.removeAttribute('colspan');
                        firstCell.textContent = firstCell.textContent.charAt(0);
                    }

                    let firstButtonCell = self.container.querySelector('.pq-quiz-buttons-row td[data-index="' + group.indices[0] + '"]');
                    if (firstButtonCell.hasAttribute('colspan')) {
                        firstButtonCell.removeAttribute('colspan');
                    }

                    for (let i = 1; i < group.indices.length; i++) {
                        let cell = self.container.querySelector('td[data-index="' + group.indices[i] + '"]');
                        cell.style.display = 'table-cell';

                        let buttonCell = self.container.querySelector('.pq-quiz-buttons-row td[data-index="' + group.indices[i] + '"]');
                        buttonCell.style.display = 'table-cell';
                    }
                });
            }

            let letterCells = this.container.querySelectorAll('.pq-quiz-word-row td:not(.pq-quiz-speaker-cell)');
            Array.from(letterCells).forEach(function(cell) {
                cell.innerHTML = cell.textContent;
            });

            let resultIcons = this.container.getElementsByClassName('pq-quiz-result-icon');
            Array.from(resultIcons).forEach(function(icon) {
                icon.remove();
            });

            let pqQuizAnswerButtons = this.container.getElementsByClassName('pq-quiz-answer-button');
            Array.from(pqQuizAnswerButtons).forEach(function(button) {
                button.checked = false;
                button.style.display = 'inline-block';
            });

            if (self.startPcued) {
                for (let i = 0; i < letterCells.length; i++) {
                    self.applyPcue(i);
                }
            }

            if(self.includeSegments){
                self.addAllSegments();
            }
        }


        // let buttonRows = this.container.querySelector('.pq-quiz-buttons-row');
        // buttonRows.style.visibility = 'visible';

        this.blinker = null;

        this.gameStatus = this.GAME_STATUS_STARTED;

        if (this.options.onReset) {
            this.options.onReset();
        }

        this.playerLocked = false;
    }

    endGame() {
        this.gameStatus = this.GAME_STATUS_ENDED;
        console.log('Game over!');

        this.countCompleted++;

        this.isFinishing = true;

        let self = this;
        let change = true;
        let interval = 1000;

        if (this.playOnFinished) {
            this.blinker = setInterval(function() {
                if (change) {
                    self.container.style.backgroundColor = '#80FFFF';
                    self.container.style.color = '#ffffff';
                    self.container.style.borderColor = 'green';
                    self.hideSegments();
                } else {
                    self.container.style.backgroundColor = '#ffffff';
                    self.container.style.color = '#000000';
                    self.container.style.borderColor = 'blue';
                    self.showSegments();
                }

                change = !change;
            }, interval);
        }

        // let buttonRows = this.container.querySelector('.pq-quiz-buttons-row');
        // buttonRows.style.visibility = 'hidden';
        this.showSuccess();

        if (this.playOnFinished) {
            this.playerLocked = true;
            setTimeout(function() {
                self.playHandle = self.player.playText(self.wordCorrectMessage, {
                    onStarted: function() {
                        console.log('Playing success message');
                    },
                    onEnded: function() {
                        self.playerLocked = false;
                        console.log('Finished playing success message');

                        if (self.isFinishing) {
                            self.clearAndRestore();

                            // self.successLayer.style.visibility = 'visible';

                            // trigger callback
                            if (self.options.onCompleted) {
                                self.options.onCompleted(self.container);
                            }

                            self.isFinishing = false;
                        }
                    }
                });
            }, 500);
        }
        else {
            self.successLayer.style.visibility = 'visible';

            // trigger callback
            if (self.options.onCompleted) {
                self.options.onCompleted(self.container);
            }
        }
    }

    /**
     * stops blinking and restore visual state before blinking
     */
    clearAndRestore() {
        if (this.blinker) {
            clearInterval(this.blinker);

            this.container.style.backgroundColor = '#ffffff';
            this.container.style.color = '#000000';
            this.container.style.borderColor = 'blue';
            this.showSegments();
        }
    }

    removePcue(index) {
        let cell = this.container.querySelector('.pq-quiz-word-row td[data-index="' + index + '"]');
        cell.innerHTML = cell.textContent;
    }

    applyPcue(index, options) {
        index = parseInt(index);

        if (typeof this.pcueInfo['letter-format'][index] !== 'undefined') {
            let cell = this.container.querySelector('.pq-quiz-word-row td[data-index="' + index + '"]');
            cell.innerHTML = '<span class="' + this.pcueInfo['letter-format'][index] + '">' + cell.textContent + '</span>';
        }

        if (typeof options !== 'undefined' && options.excludeGroup) {
            return;
        }

        // check groups
        if (this.pcueInfoGroups) {
            for (let i = 0; i < this.pcueInfoGroups.length; i++) {
                let group = this.pcueInfoGroups[i];
                if (group.indices.includes(index)) {
                    let cell = this.container.querySelector('.pq-quiz-word-row td[data-index="' + index + '"]');
                    cell.innerHTML = '<span class="' + group.style + '">' + cell.innerHTML + '</span>';

                    if (typeof options !== 'undefined' && options.onAfterGroupPcueApply) {
                        options.onAfterGroupPcueApply(group);
                    }

                    break;
                }
            }
        }
    }

    getSpokenInstructions(){
        let pqTypeText = "";

        if (this.pqType === 'Letter Name') {
            pqTypeText = " name ";
        } else if(this.pqType === 'Silent'){
            pqTypeText = " silent ";
        } else if(this.pqType === 'Common'){
            pqTypeText = " common ";
        } else if(this.pqType === 'Rotated R'){
            pqTypeText = ", er, ";
        } else if (this.pqType === 'Schwa') {
            pqTypeText = ", uh, ";
        } else if (this.pqType === 'Stretch'){
            pqTypeText = " drawn-out exception ";
        } else if (this.pqType === 'Blended'){
            pqTypeText = " blended ";
        } else if (this.pqType === 'Combined'){
            pqTypeText = " combined ";
        } else if (this.pqType === 'Raised Exception'){
            pqTypeText = " higher exception ";
        } else if (this.pqType === 'Dropped Exception'){
            pqTypeText = " lower exception ";
        } else {
            console.log('Unrecognized pq type');
        }

        let spokenInstruction = "Now,";

        if (this.gameMode === 'segmentation'){
            spokenInstruction += " look for syllable breaks."
        }else if(this.gameMode === 'look'){
            spokenInstruction += " look for" + pqTypeText + "PQs";
        } else {
            if (this.pqType == 'Silent'){
                spokenInstruction +=  " listen for letters that are silent."
            } else {
                spokenInstruction += " listen for" + pqTypeText + "sounds.";
            }
        }

        return spokenInstruction;
    }

    addInstructionLayer(){
        let imageUrl = "";

        if (this.gameMode === 'segmentation'){
            imageUrl = '/wp-content/uploads/2018/05/segments.png';
        } else {
            imageUrl = '/wp-content/uploads/2018/04/';

            if (this.gameMode === 'look'){
                imageUrl += 'see-';
            } else {
                imageUrl += 'hear-';
            }

            if (this.pqType === 'Letter Name') {
                imageUrl += "name";
            } else if(this.pqType === 'Silent'){
                imageUrl += "silent";
            } else if(this.pqType === 'Common'){
                imageUrl += "common";
            } else if(this.pqType === 'Rotated R'){
                imageUrl += "er";
            } else if (this.pqType === 'Schwa') {
                imageUrl += "uh";
            } else if (this.pqType === 'Stretch'){
                imageUrl += "drawn";
            } else if (this.pqType === 'Blended'){
                imageUrl += "blended";
            } else if (this.pqType === 'Combined'){
                imageUrl += "combined";
            } else if (this.pqType === 'Raised Exception'){
                imageUrl += "higher";
            } else if (this.pqType === 'Dropped Exception'){
                imageUrl += "lower";
            } else {
                console.log('Unrecognized pq type');
            }
            imageUrl += ".png";
        }

        //compute sizing of image based on letter sizes
        let curWord = this.word;
        let letterSizeSum = 12;
        for (let i = 0; i < curWord.length; i++){
            if (curWord[i].toLowerCase() === "m"){
                letterSizeSum += 10
            } else if (curWord[i].toLowerCase() === "w"){
                letterSizeSum += 9
            } else {
                letterSizeSum += 8
            }
        }

        //adjust sizing of image based on word length
        if (curWord.length == 2){
            letterSizeSum += 16;
        } else if(curWord.length == 3){
            letterSizeSum += 8;
        }

        let iconPercentage = Math.round(1200 / letterSizeSum);

        let layer = document.createElement('div');

        this.container.setAttribute('instruction-layer', 1);
        layer.classList.add('pq-quiz-set-changed-instructions');
        layer.style.position = 'absolute';
        layer.style.height = '100%';
        layer.style.width = '100%';
        layer.style.left = '0';
        layer.style.top = '0';
        layer.style.verticalAlign = 'middle';

        let layerImg = document.createElement('img');
        layerImg.setAttribute('src', imageUrl);
        layerImg.style.width = (100-iconPercentage).toString() + '%';
        layerImg.style.height = '100%';
        layerImg.style.paddingLeft = (iconPercentage).toString() + '%';

        layer.appendChild(layerImg);

        let self = this;

        layer.addEventListener('click', function(){
            self.container.removeAttribute('instruction-layer');

            layer.remove();

            self.container.removeAttribute('instruction-layer');
            self.removeInstructionLayer();
            if(self.autoPlayWord && !self.startPcued){
                self.playWordAudio();
            }

        });

        this.container.appendChild(layer);
    }

    showInstructionLayer(){
        if (!this.container.hasAttribute('instruction-layer')){
            this.container.setAttribute('instruction-layer', 1);
            this.addInstructionLayer();
        }

        this.hideNonIcon();
    }

    removeInstructionLayer(){
        if (this.container.hasAttribute('instruction-layer')){
            this.container.removeAttribute('instruction-layer');
            let layer = this.container.getElementsByClassName('pq-quiz-set-changed-instructions')[0];
            layer.remove();
        }

        this.showNonIcon();
    }

    /**
     * merge cells that belongs to a pcue group
     * @param group
     */
    mergeGroup(group) {
        let newCell = this.container.querySelector('td[data-index="' + group.indices[0] + '"]');
        this.removePcue(group.indices[0]);
        this.applyPcue(group.indices[0], {excludeGroup: true});

        let newValue = newCell.innerHTML;

        for (let i = 1; i < group.indices.length; i++) {
            let index = group.indices[i];

            // hide the rest of the group cells
            this.container.querySelector('.pq-quiz-button-container [data-index="' + index + '"]').parentNode.style.display = 'none';

            this.removePcue(index);
            this.applyPcue(index, {excludeGroup: true});

            let letterCell = this.container.querySelector('td[data-index="' + index + '"]');
            newValue += letterCell.innerHTML;
            letterCell.style.display = 'none';
        }

        // merge buttons
        this.container.querySelector('.pq-quiz-button-container [data-index="' + group.indices[0] + '"]')
            .parentNode
            .setAttribute('colspan', group.indices.length.toString());


        newCell.setAttribute('colspan', group.indices.length.toString());
        newCell.innerHTML = '<span class="' + group.style + '">' + newValue + '</span>';
    }

    isGroupAllAnswered(group) {
        let self = this;
        let answered = 0;

        if (this.gameMode === 'segmentation') {
            return false;
        }

        group.indices.forEach(function(index) {
            let button = self.container.querySelector('.pq-quiz-button-container [data-index="' + index + '"]');
            if (button.style.display === 'none') {
                answered++;
            }
        });

        return (answered === group.indices.length);
    }

    markCorrectSegmentButtons(){
        let answerButtons = this.container.querySelectorAll('.segmentation-button-container');
        for(let i = 0; i < this.pcueInfo.pcuebreak.length; i++){
            let nextCorrectIndex = this.pcueInfo.pcuebreak[i];
            let curButtonIndex = 0;
            while(curButtonIndex < answerButtons.length){
                if (nextCorrectIndex === curButtonIndex){
                    if(this.gameMode === 'segmentation'){
                        answerButtons[curButtonIndex].classList.add('pq-quiz-correct');
                    }
                    answerButtons[curButtonIndex].classList.add('segment-cell');
                    break;
                } else {
                    curButtonIndex++;
                }
            }
        }
    }

    addSegment(index){
        let wordRow = this.container.querySelector('.pq-quiz-word-row');
        let cell = wordRow.querySelector("[data-index='" + index + "']").nextSibling;

        while(cell.nextSibling && cell.tagName !== 'TD'){
            cell = cell.nextSibling;
        }

        cell.innerHTML = "";
        cell.style.paddingTop = '1vw';

        let segment = document.createElement('div');
        segment.classList.add('segment');
        segment.style.margin = '0';
        segment.style.padding = '0';
        segment.style.width = '0.75vw';
        segment.style.height = '0.75vw';
        segment.style.backgroundColor = 'black';
        segment.style.marginLeft = '0.5vw';
        segment.style.marginRight = '0.5vw';
        segment.innerHTML = '&nbsp;';

        cell.appendChild(segment);
    }

    answer(button) {
        let self = this;
        let message = '';
        let imgResult = null;
        let isCorrect = false;

        isCorrect = button.parentNode.classList.contains('pq-quiz-correct');

        if (this.gameMode === 'segmentation'){
            if (isCorrect){
                imgResult = CHECK_IMAGE;
                message = 'correct';
                this.addSegment(button.parentNode.getAttribute('data-index'));
                button.style.display = 'none';
                this.openedCorrect++;
            } else {
                message = 'please try again';
                imgResult = TRY_AGAIN_IMAGE;
                button.style.borderBottomColor = 'red';
            }

            if(this.openedCorrect !== this.countCorrect){
                let imgContainer = document.createElement('div');
                imgContainer.classList.add('pq-quiz-set-fly-in-container');
                imgContainer.style.width = '100%';
                imgContainer.style.height = '100%';
                imgContainer.style.textAlign = 'center';
                imgContainer.style.verticalAlign = 'middle';
                imgContainer.style.position = 'absolute';
                imgContainer.style.top = '0';
                imgContainer.style.left = '0';

                let iconResult = document.createElement('img');

                iconResult.setAttribute('src', imgResult);
                iconResult.classList.add('pq-quiz-result-icon');
                iconResult.style.height = '100%';

                imgContainer.appendChild(iconResult);

                this.container.parentNode.insertBefore(imgContainer, this.container.nextSibling);

                setTimeout(function() {
                    imgContainer.remove();
                }, 1000);
            }

        } else {
            if (isCorrect) {
                if(button.parentNode.hasAttribute('data-letter-correct-message')){
                    message = button.parentNode.getAttribute('data-letter-correct-message');
                } else {
                    message = this.letterCorrectMessage;
                }

                imgResult = CHECK_IMAGE;
                this.openedCorrect++;
            } else {
                message = button.parentNode.getAttribute('data-letter-incorrect-message');
                imgResult = X_IMAGE;
            }

            let iconResult = document.createElement('img');

            iconResult.setAttribute('src', imgResult);
            iconResult.classList.add('pq-quiz-result-icon');
            iconResult.style.width = button.style.width;
            iconResult.style.height = button.style.height;
            button.style.display = 'none';

            button.parentNode.insertBefore(iconResult, button.nextSibling);

            // apply pcue
            this.applyPcue(button.getAttribute('data-index'), {
                onAfterGroupPcueApply: function(group) {
                    if (self.isGroupAllAnswered(group)) {
                        // merge cells
                        self.mergeGroup(group);
                    }
                }
            });

            button.checked = false; // @fixme: to be removed
        }

        if(this.openedCorrect === this.countCorrect){
            this.playLetterCorrectMessage = false;
        }

        if (this.playLetterCorrectMessage || !isCorrect) {
            console.log('Requesting to play: ' + message);
            if (!this.playerLocked) {
                this.playHandle = this.player.playText(message, {
                    onStarted: function() {
                        console.log('Playing answer message.');
                    },
                    onEnded: function() {
                        console.log('Done playing answer message');
                    }
                });
            }
        }

        if (this.options.onAnswered) {
            this.options.onAnswered();
        }

        if (self.openedCorrect > 0 && self.openedCorrect >= self.countCorrect) {
            this.playerLocked = true;
            self.endGame();
        }
    }

    show() {
        this.container.style.display = 'inline-block';
    }

    hide() {
        this.container.style.display = 'none';
    }

    stopPlay() {
        if (this.playHandle) {
            this.playHandle.stop();
            this.playerLocked = false;
        }

        if (this.isFinishing) {
            console.log('Finishing');
            this.clearAndRestore();

            // this.successLayer.style.visibility = 'visible';
            // this.showSuccess();

            // trigger callback
            if (this.options.onCompleted) {
                this.options.onCompleted(this.container);
            }

            this.isFinishing = false;
        }
    }

    showAnswerButtons() {
        let buttonsRow = this.container.querySelector('.pq-quiz-buttons-row');
        let buttonCells = buttonsRow.querySelectorAll('td:not(.pq-quiz-speaker-cell)');
        Array.from(buttonCells).forEach(function(cell) {
            cell.style.opacity = '1';
        });
    }

    hideAnswerButtons() {
        let buttonsRow = this.container.querySelector('.pq-quiz-buttons-row');
        let buttonCells = buttonsRow.querySelectorAll('td:not(.pq-quiz-speaker-cell)');
        Array.from(buttonCells).forEach(function(cell) {
            cell.style.opacity = '0';
        });
    }

    showWordRow() {
        let wordRow = this.container.querySelector('.pq-quiz-word-row');
        let letterCells = wordRow.querySelectorAll('td:not(.pq-quiz-speaker-cell)');
        Array.from(letterCells).forEach(function(cell) {
            cell.style.opacity = '1';
        });
    }

    hideWordRow(){
        let wordRow = this.container.querySelector('.pq-quiz-word-row');
        let letterCells = wordRow.querySelectorAll('td:not(.pq-quiz-speaker-cell)');
        Array.from(letterCells).forEach(function(cell) {
            cell.style.opacity = '0';
        });
        wordRow.style.visibility = 'none';
    }

    hideSegments(){
        let segments = this.container.querySelectorAll('.segment');
        Array.from(segments).forEach(function(seg) {
            seg.style.backgroundColor = '#ffffff';
        });
    }

    showSegments(){
        let segments = this.container.querySelectorAll('.segment');
        Array.from(segments).forEach(function(seg) {
            seg.style.backgroundColor = '#000000';
        });
    }

    hideNonIcon(){
        this.hideAnswerButtons()
        this.hideWordRow();
        this.hideSegments();
    }

    showNonIcon(){
        this.showAnswerButtons()
        this.showWordRow();
        this.showSegments();
    }

    playWordAudio(){
        this.stopAudio();
        this.playHandle = this.player.playText(this.word, {
            onStarted: function() {
                console.log('Playing current word');
            },
            onEnded: function() {
                console.log('Done playing currentWord');
            }
        });
    }

    stopAudio() {
        if (!this.playHandle) {
            return;
        }

        this.playHandle.stop();
        this.playHandle = null;
    }

    fade() {
        this.container.querySelector('.pq-quiz-table-container').style.opacity = '.3';
    }

    unfade() {
        this.container.querySelector('.pq-quiz-table-container').style.opacity = '1';
    }
}

class PqQuizSet {

    constructor(container) {
        this.container = container;
        this.player = null;
        this.playHandle = null;
        this.quizzes = [];

        this.quizSetId = '';
        this.hasQuizLogo = false;
        this.hasQuizLightBulb = false;
        this.hasTopSeparator = false;
        this.hasBottomSeparator = false;
        this.completeQuizMessage = '';
        this.highlightColor = '';

        this.GAME_STARTED = 1;
        this.GAME_FINISHED = 2;

        this.state = this.GAME_STARTED;

        this.curQuizIndex = 0;
        this.completedCount = 0;

        this.leftArrow = null;
        this.rightArrow = null;

        this.borderRadius = null;

        this.moveBySkipped = false;
        this.skipWarningSwitch = true;

        this.initialize();
    }

    initialize() {
        let self = this;

        this.quizSetId = this.container.id;

        if (this.container.hasAttribute('data-set-include-quiz-logo')){
            this.hasQuizLogo = true;
        }

        if (this.container.hasAttribute('data-set-include-quiz-light-bulb')){
            this.hasQuizLightBulb = true;
        }

        if (this.container.hasAttribute('data-set-highlight-color')){
            this.highlightColor = this.container.getAttribute('data-set-highlight-color');
        } else {
            this.highlightColor = 'green';
        }

        if(this.container.hasAttribute('data-set-include-top-separator')){
            this.hasTopSeparator = true;
        }

        if(this.container.hasAttribute('data-set-include-bottom-separator')){
            this.hasBottomSeparator = true;
        }

        if (this.container.hasAttribute('data-set-complete-quiz-message')){
            this.completeQuizMessage = this.container.getAttribute('data-set-complete-quiz-message');
        } else {
            this.completeQuizMessage = "You got all the words correct!";
        }

        let quizComponents = this.container.querySelectorAll('.pq-quiz-component');
        if (quizComponents.length <= 0) {
            console.log('ERROR: No quiz components found.');
            return;
        }

        let onCompleted = function(container) {
            self.skipWarningSwitch = true;

            if (container.hasAttribute('data-skipped')) {
                container.removeAttribute('data-skipped');
            }

            if (!container.hasAttribute('data-completed')) {
                container.setAttribute('data-completed', '');
                self.completedCount++;
            }

            self.checkCompletedCount();
        };

        let onAnswered = function() {
            self.skipWarningSwitch = true;
        };

        Array.from(quizComponents).forEach(function(quiz) {
          let options = {};
          options.onCompleted = onCompleted;
          options.onAnswered = onAnswered;

          self.quizzes.push(new PqQuizComponent(quiz, options));
        });

        // disable playing of success message on last word
        // self.quizzes[self.quizzes.length - 1].disablePlayOnFinish();

        // remove non pq quiz components
        Array.from(this.container.childNodes).forEach(function(node) {
            if(node.classList && node.classList.contains('pq-quiz-main-component')){
                let quizComponent = node.querySelector('.pq-quiz-component');
                self.container.insertBefore(quizComponent, node);
                node.remove();
            } else if (!node.classList || !node.classList.contains('pq-quiz-component') || node.class) {
                node.remove();
            } else {
                //keep node
            }
        });

        if (this.hasTopSeparator) {
            let topSeparator = document.createElement('hr');
            this.container.parentNode.insertBefore(topSeparator, this.container);
        }

        if (this.hasQuizLogo){

            let pqQuizTitleDiv = document.createElement('div');
            pqQuizTitleDiv.classList.add('pq-quiz-set-title');

            window.addEventListener('resize', function(e){
                pqQuizTitleDiv.style.height = Math.floor(pqQuizTitleDiv.offsetWidth * 0.1).toString() + 'px';
            });

            let quizLogoImage = document.createElement('img');
            quizLogoImage.classList.add('quiz-logo');
            quizLogoImage.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/02/PQ-Game-300x86.jpg');
            quizLogoImage.style.width = '25%'
            quizLogoImage.style.height= '10%';
            quizLogoImage.style.float = 'left';

            let quizLogoContainer = document.createElement('div');
            quizLogoContainer.appendChild(quizLogoImage);

            pqQuizTitleDiv.appendChild(quizLogoContainer);
            pqQuizTitleDiv.appendChild(document.createElement('br'));

            this.container.parentNode.insertBefore(pqQuizTitleDiv, this.container);

        }

        if (this.hasQuizLightBulb){

            let quizLightBulbHint = document.createElement('img');
            quizLightBulbHint.classList.add('quiz-light-bulb-hint');
            quizLightBulbHint.classList.add('image-audio');
            quizLightBulbHint.setAttribute('title', 'PQ Games exercise students phonemic awareness and PQ knowledge.');
            quizLightBulbHint.setAttribute('data-title', 'PQ Games exercise students phonemic awareness and PQ knowledge.');
            quizLightBulbHint.setAttribute('data-orig-text', 'PQ Games exercise students phonemic awareness and PQ knowledge.');
            quizLightBulbHint.setAttribute('data-audio-text', 'PQ Games exercise students phonemic awareness and PQ knowledge.');
            quizLightBulbHint.setAttribute('alt','');
            quizLightBulbHint.setAttribute('data-play-on-click','1');
            quizLightBulbHint.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2017/11/hint.png');
            quizLightBulbHint.style.width = '100%';
            quizLightBulbHint.style.height = '100%';

            let quizLightBulbContainer = document.createElement('span');
            quizLightBulbContainer.appendChild(quizLightBulbHint);
            quizLightBulbContainer.style.float = 'right';
            quizLightBulbContainer.style.width = '10%';
            quizLightBulbContainer.style.height = '10%';

            this.container.parentNode.insertBefore(quizLightBulbContainer, this.container);
        }

        let instructionPlayAudio = document.createElement('span');
        instructionPlayAudio.setAttribute('id', this.quizSetId + '-audio-container');
        instructionPlayAudio.classList.add('playable-audio-wrapper');
        instructionPlayAudio.classList.add('pq-quiz-playable-audio-container');
        instructionPlayAudio.setAttribute('data-speed', '1,000');
        instructionPlayAudio.setAttribute('data-highlight-color', this.highlightColor);
        instructionPlayAudio.setAttribute('data-button-loc', 'start');
        instructionPlayAudio.setAttribute('data-block-id', 'play-block-' + this.quizSetId);

        let playableAudioImage = document.createElement('img');
        playableAudioImage.classList.add('playable-audio');
        playableAudioImage.style.height = '1em';
        playableAudioImage.setAttribute('src', "https://mlc.learningstewards.org/wp-content/uploads/2018/01/speaker-active.png");
        playableAudioImage.setAttribute('data-highlight-with-audio', 'true');

        instructionPlayAudio.appendChild(playableAudioImage);

        this.container.parentNode.insertBefore(instructionPlayAudio, this.container);
        this.container.parentNode.insertBefore(document.createElement('br'), this.container);

        let leftArrowContainer = null;
        let rightArrowContainer = null;

        if (this.quizzes.length > 1) {
            // place the buttons
            leftArrowContainer = document.createElement('div');
            leftArrowContainer.style.display = 'inline-block';
            leftArrowContainer.style.position = 'relative';
            leftArrowContainer.style.height = '100%';

            this.leftArrow = document.createElement('img');
            this.leftArrow.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/02/pqq-l-arrow.png');
            this.leftArrow.classList.add('pq-quiz-set-left-arrow');

            this.leftArrow.addEventListener('click', function(e) {
                if (self.state === self.GAME_FINISHED) {
                    return;
                }

                self.movePrevious();
            });

            leftArrowContainer.appendChild(this.leftArrow);

            this.disable(this.leftArrow);

            rightArrowContainer = document.createElement('div');
            rightArrowContainer.style.display = 'inline-block';
            rightArrowContainer.style.position = 'relative';
            rightArrowContainer.style.height = '100%';

            this.rightArrow = document.createElement('img');
            this.rightArrow.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/02/pqq-r-arrow.png');
            this.rightArrow.classList.add('pq-quiz-set-right-arrow');

            this.rightArrow.addEventListener('click', function(e) {
                if (self.state === self.GAME_FINISHED) {
                    return;
                }

                if (self.completedCount === (self.quizzes.length - 1) && self.getCurQuiz().isFinishing)
                {
                    console.log('Found end');
                    self.getCurQuiz().stopPlay();
                } else {
                    console.log('Moving to next word');
                    self.moveNext();
                }
            });

            rightArrowContainer.appendChild(this.rightArrow);

            this.container.insertBefore(leftArrowContainer, this.container.firstChild);
            this.container.appendChild(rightArrowContainer);
        }

        let wordImageContainer = document.createElement('div');
        wordImageContainer.setAttribute('id', this.quizSetId + '-word-image-container');
        wordImageContainer.classList.add('pq-quiz-word-image-container');
        wordImageContainer.style.display = 'inline-block';
        wordImageContainer.style.position = 'relative';
        wordImageContainer.style.height = '100%';

        this.container.insertBefore(wordImageContainer, this.container.firstChild);

        this.changeWordImage(this.getCurQuiz().wordImageUrl);
        this.changeInstructionHeader();

        this.container.addEventListener('pqQuizCompleted', function(e) {
            console.log('Fired from: ' + e.target);
        });

        this.borderWidth = this.getCurQuiz().container.style.borderWidth;
        this.borderRadius = this.getCurQuiz().container.style.borderRadius;

        // show first quiz
        this.showQuiz(this.getCurQuiz());

        // get the computed height of the first quiz box and apply to the set
        let style = window.getComputedStyle(this.getCurQuiz().container);
        this.container.style.height = style.getPropertyValue('height');

        if (this.quizzes.length > 1) {
            // needed for height adjustments of the left and right arrow scaling
            let widthApplied = false;
            let widthIntervalCheck = null;

            let applyWidth = function() {
                style = window.getComputedStyle(self.leftArrow);
                if (style.getPropertyValue('width') === '0' || style.getPropertyValue('width') === '0px') {
                    return;
                }

                leftArrowContainer.style.width = style.getPropertyValue('width');

                style = window.getComputedStyle(self.rightArrow);
                if (style.getPropertyValue('width') === '0' || style.getPropertyValue('width') === '0px') {
                    return;
                }

                rightArrowContainer.style.width = style.getPropertyValue('width');

                widthApplied = true;
                if (widthIntervalCheck) {
                    clearInterval(widthIntervalCheck);
                }
            };

            applyWidth();
            if (!widthApplied) {
                widthIntervalCheck = setInterval(function() {
                    applyWidth();
                }, 100);
            }

        }

        let curPqType = this.getCurQuiz().pqType;
        let curGameMode = this.getCurQuiz().gameMode;

        let quizTopSpace = document.createElement('br');
        this.container.parentNode.insertBefore(quizTopSpace, this.container);

        if(this.hasBottomSeparator){
            let bottomSeparator = document.createElement('hr');
            this.container.parentNode.insertBefore(bottomSeparator, this.container.nextSibling);
        }

        let quizBottomSpace = document.createElement('br');
        this.container.parentNode.insertBefore(quizBottomSpace, this.container.nextSibling);

    }

    /**
    * modified version from TextPlayer Class
    * @param audioContainer
    */
    prepareBlock(audioContainer) {

        let instructionPlayAudio = audioContainer;

        // monitors instance number of word
        let wordMonitor = new Map();

        // keeps track of the word index
        let wordIndexCtr = 1;

        // ##############################  HEART STARTS HERE  ##############################
        let regEx = new RegExp(/[A-Za-z0-9\u2018-\u2019'"%\-]+(?!([^<]+)?>)/g);
        let docFrag = document.createDocumentFragment();

        /**
         * finds all the words in the node and set metadata in preparation for audio block playing
         * @param node
         * @returns {number}
         */
        let traverse = function(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                // extract words

                let tempNode = document.createElement('span');
                tempNode.innerHTML = node.textContent.replace(regEx, function(match, p1, p2) {
                    // preserve spaces
                    if (match === 'nbsp') {
                        return match;
                    }

                    if (wordMonitor.has(match)) {
                        wordMonitor.set(match, wordMonitor.get(match) + 1);
                    } else {
                        wordMonitor.set(match, 1);
                    }

                    let span = document.createElement('span');

                    span.classList.add('word');
                    span.setAttribute('data-word', match);
                    span.setAttribute('data-word-orig', match.toLowerCase()); // used for word replacements
                    span.setAttribute('data-word-instance', wordMonitor.get(match));
                    span.setAttribute('data-word-index', wordIndexCtr.toString());
                    span.textContent = match;

                    wordIndexCtr++;

                    return span.outerHTML;
                });

                let countAddedNotes = tempNode.childNodes.length;
                while (tempNode.hasChildNodes()) {
                    docFrag.appendChild(tempNode.firstChild);
                }

                node.parentNode.replaceChild(docFrag, node);

                // This is used to jump the traversal of nodes since new nodes added are live and should not
                // be reprocessed
                return countAddedNotes - 1;
            }
            else if (node.classList.contains('word')) {
                let match = node.textContent;

                if (wordMonitor.has(match)) {
                    wordMonitor.set(match, wordMonitor.get(match) + 1);
                } else {
                    wordMonitor.set(match, 1);
                }

                node.setAttribute('data-word', match);
                node.setAttribute('data-word-orig', match.toLowerCase()); // used for word replacements
                node.setAttribute('data-word-instance', wordMonitor.get(match));
                node.setAttribute('data-word-index', wordIndexCtr.toString());

                wordIndexCtr++;
            }
            else if (node.hasChildNodes()) {
                for (let i = 0; i < node.childNodes.length; i++) {
                    i += traverse(node.childNodes[i]);
                }
            }

            return 0;
        };

        let combineWordNodes = function(wordA, wordB) {
            let newWord = wordA.textContent + wordB.textContent;

            let adjustWordIndices = function(wordNode) {
                let sameWords = instructionPlayAudio.querySelectorAll('span[data-word="' + wordNode.textContent + '"]');
                for (let i = 0; i < sameWords.length; i++) {
                    let compWord = sameWords[i];

                    if (parseInt(compWord.getAttribute('data-word-instance')) > parseInt(wordNode.getAttribute('data-word-instance'))) {
                        compWord.setAttribute('data-word-instance', parseInt(compWord.getAttribute('data-word-instance')) - 1);
                    }
                }
            };

            adjustWordIndices(wordA);
            adjustWordIndices(wordB);

            // find common parent and wrap word from there
            let wordAParent = wordA.parentNode;
            let wordBParent = wordB.parentNode;


            while (wordAParent !== wordBParent) {
                if (wordBParent.parentNode === instructionAudioContainer) {
                    break;
                }

                wordBParent = wordBParent.parentNode;
            }
        };

        let openWord = null;
        let mergeWords = function(node) {
            if (node.nodeName.toLowerCase() === 'span' && node.classList.contains('word')) {
                if (openWord !== null) {
                    combineWordNodes(openWord, node);
                }

                if (node === node.parentNode.lastChild) {
                    openWord = node;
                }
            }
            else if (node.hasChildNodes()) {
                for (let i = 0; i < node.childNodes.length; i++) {
                    mergeWords(node.childNodes[i]);
                }
            } else {
                openWord = null;
            }
        };

        traverse(audioContainer);

        // ##############################   HEART ENDS HERE  ###############################

        // @todo: find a way to make this transfer this behaviior to the highlighter
        if (this.highlightColor === 'pcued') {
            // adjust the widths
            let wordNodes = audioContainer.getElementsByClassName('word');
            // for (let node of wordNodes) {
            for (let i = 0; i < wordNodes.length; i++) {
                let node = wordNodes[i];

                node.style.width = (node.offsetWidth * 1.2) + 'px';
                node.style.display = 'inline-block';
            }
        }

        return audioContainer;
    }

    /**
    * Replaces the play audio instructions with the current quiz instructions
    * calls: getCurQuiz(), prepareBlock()
    */
    changeInstructionHeader(){
        let gameMode = this.getCurQuiz().gameMode;
        let pqType = this.getCurQuiz().pqType;

        let instructionSentence = this.getCurQuiz().instructionSentence;
        instructionSentence = " " + instructionSentence;

        //clear instructions
        let audioContainer = this.container.parentNode.querySelector('#' + this.quizSetId + '-audio-container');

        while(audioContainer.firstChild.nextSibling){
            audioContainer.lastChild.remove();
        }

        let instructionTextNode = document.createTextNode(instructionSentence);
        audioContainer.appendChild(instructionTextNode);

        audioContainer = this.prepareBlock(audioContainer);

        let boldWordsList = ["name", "silent", "common", "‘uh’", "'uh'", "uh",
                            "'er'", "‘er’", "er", "lower", "higher", "drawn-out",
                            "combined", "blended", "look", "listen", "exception",
                            "letters", "letter", "two", "every", "all", "rotated", "r"];

        let wordNodes = audioContainer.querySelectorAll('.word');

        for(let i = 0; i < wordNodes.length; i++){
            let curWord = wordNodes[i].textContent;
            let curWordOrig = curWord.toLowerCase();
            if (boldWordsList.indexOf(curWordOrig) > -1) {
                wordNodes[i].style.fontWeight = 'bold';
                if (curWordOrig === "look"){
                    wordNodes[i].style.color = "red";
                } else if (curWordOrig === "listen"){
                    wordNodes[i].style.color = "green";
                }
            }
        }

        let colon = document.createElement('span');
        colon.appendChild(document.createTextNode(':'));

        audioContainer.appendChild(colon);
    }//changeInstructionHeader

    /**
    * If the there is a change in pqType or gameMode,
    * add an instruction layer to the current quiz container
    * @param prevPqType
    * @param prevGameMode
    */
    changeInstructionLayer(prevPqType, prevGameMode){

        let curPqType = this.getCurQuiz().pqType;
        let curGameMode = this.getCurQuiz().gameMode;

        if (prevPqType !== curPqType || prevGameMode !== curGameMode){
            this.getCurQuiz().showInstructionLayer();

            let spokenInstructions = this.getCurQuiz().getSpokenInstructions();

            if(this.player){
                this.playHandle = this.player.playText(spokenInstructions);
            }
        } else {
            this.getCurQuiz().removeInstructionLayer();
            if (!this.getCurQuiz().startPcued && this.getCurQuiz().autoPlayWord) {
                this.getCurQuiz().playWordAudio();
            }
        }//if/else: show or remove


    }//changeInstructionLayer

    /**
    * Replaces word image to the current the incoming image
    * @param imgURL
    */
    changeWordImage(imageUrl) {

        let imageContainer = this.container.parentNode.querySelector('#' + this.quizSetId + '-word-image-container');

        if(imageContainer.children.length === 1){
            imageContainer.removeChild(imageContainer.childNodes[0]);
        }

        if(imageUrl){
            let wordImage = document.createElement('img');
            wordImage.setAttribute('src', imageUrl);

            wordImage.style.height = '100%';
            wordImage.style.width = '100%';
            wordImage.style.textAlign = 'center';
            wordImage.style.verticalAlign = 'middle';
            wordImage.style.position = 'absolute';
            wordImage.style.top = '0';
            wordImage.style.left = '0';
            imageContainer.style.display = "inline-block";
            imageContainer.appendChild(wordImage);
        }else{
            imageContainer.style.display = "none";
        }
    }

    /**
     * checks to see if all the quizzes have been answered
     */
    checkCompletedCount() {
        let self = this;
        if (this.completedCount === this.quizzes.length) {
            this.state = this.GAME_FINISHED;

            this.changeWordImage("");
            this.changeInstructionHeader();

            // create layer
            let finishedLayer = document.createElement('div');
            finishedLayer.classList.add('pq-quiz-set-finished-layer');
            finishedLayer.style.height = '100%';
            finishedLayer.style.width = '100%';
            finishedLayer.style.textAlign = 'center';
            finishedLayer.style.verticalAlign = 'middle';
            finishedLayer.style.position = 'absolute';
            finishedLayer.style.top = '0';
            finishedLayer.style.left = '0';

            let message = document.createElement('img');
            message.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/02/pqq-all-correct.png');
            message.style.height = '100%';
            message.style.borderColor = 'blue';
            message.style.borderStyle = 'solid';
            message.style.borderWidth = this.borderWidth;
            message.style.borderRadius = this.borderRadius;

            finishedLayer.appendChild(message);

            // hide the elements
            if (this.quizzes.length > 1) {
                this.container.querySelector('.pq-quiz-set-left-arrow').style.visibility = 'hidden';
                this.container.querySelector('.pq-quiz-set-right-arrow').style.visibility = 'hidden';
            }

            this.getCurQuiz().container.setAttribute('data-completed', '');
            this.getCurQuiz().hide();

            this.container.appendChild(finishedLayer);

            if (this.container.hasAttribute('data-set-complete-quiz-message')) {
                this.playHandle = this.player.playText(this.container.getAttribute('data-set-complete-quiz-message'));
            } else {
                this.playHandle = this.player.playText('Well done! You got them all!');
            }

            finishedLayer.addEventListener('click', function(e) {
                this.remove();
                self.reset();
            });
        }
    }

    /**
     * start the set over
     */
    reset() {
        this.stopAudio();
        this.curQuizIndex = 0;
        this.completedCount = 0;
        this.moveBySkipped = false;
        this.skipWarningSwitch = true;

        // reset every word in the set
        this.quizzes.forEach(function(quiz) {
            quiz.container.removeAttribute('data-completed');
            quiz.container.removeAttribute('data-skipped');
            quiz.reset();
        });

        // reset the arrows
        if (this.quizzes.length > 1) {
            this.leftArrow.style.visibility = 'visible';
            this.rightArrow.style.visibility = 'visible';

            this.disable(this.leftArrow);
            this.enable(this.rightArrow);
        }

        // officially start the game
        this.state = this.GAME_STARTED;
        this.getCurQuiz().show();
        this.changeWordImage(this.getCurQuiz().imageUrl);
        this.changeInstructionHeader();
        this.changeInstructionLayer("","");
    }

    /**
     * whitewash an element to appear as disabled
     * @param button
     */
    disable(button) {
        button.setAttribute('disabled', '');
        button.style.opacity = '.4';
    }

    /**
     * set to full opacity to appear normal as contrasted to whitewashed state
     * works in conjuction with disable
     * @param button
     */
    enable(button) {
        button.removeAttribute('disabled');
        button.style.opacity = '1';
    }

    getCurQuiz() {
        return this.quizzes[this.curQuizIndex];
    }

    stopAudio() {
        if (!this.playHandle) {
            return;
        }

        this.playHandle.stop();
        this.playHandle = null;
    }

    /**
     * advances to the next word
     */
    moveNext() {
        let self = this;

        if (this.curQuizIndex <= this.quizzes.length - 1) {
            this.getCurQuiz().stopPlay();

            if (this.curQuizIndex === this.quizzes.length - 1) {
                this.moveBySkipped = true;
            }

            if (!this.getCurQuiz().container.hasAttribute('data-completed')) {
                // warn users that they are about to skip a word
                if (this.skipWarningSwitch) {
                    this.playHandle = this.player.playText('You are about to skip a word. Please click the right arrow again to proceed.');
                    this.skipWarningSwitch = false;
                    if(this.getCurQuiz().container.hasAttribute('instruction-layer')){
                        this.getCurQuiz().removeInstructionLayer();
                    }
                    return;
                }

                this.stopAudio();
                this.skipWarningSwitch = true;

                if (!this.getCurQuiz().container.hasAttribute('data-skipped')) {
                    // mark the word as skipped
                    this.getCurQuiz().container.setAttribute('data-skipped', '');

                    // create skipped layer
                    // @todo: transfer to CSS the static attributes
                    let layer = document.createElement('div');
                    layer.classList.add('pq-quiz-set-skipped-layer');
                    layer.style.position = 'absolute';

                    layer.style.height = '100%';
                    layer.style.width = '100%';
                    layer.style.left = '0';
                    layer.style.top = '0';

                    layer.style.textAlign = 'center';
                    layer.style.verticalAlign = 'middle';

                    this.getCurQuiz().hideAnswerButtons();

                    // let skippedContainer = document.createElement('td');
                    // skippedContainer.setAttribute('colspan', this.getCurQuiz().container.textContent.length);

                    let message = document.createElement('img');
                    message.setAttribute('src', 'https://mlc.learningstewards.org/wp-content/uploads/2018/02/skipped.png');
                    message.setAttribute('title', 'Click to finish this word');
                    // message.style.height = this.container.style.fontSize;
                    message.style.position = 'absolute';
                    message.style.height = '50%';
                    message.style.width = '100%';
                    message.style.left = '0';
                    message.style.bottom = '20%';

                    layer.appendChild(message);
                    // skippedContainer.appendChild(message);

                    this.getCurQuiz().fade();
                    this.getCurQuiz().container.appendChild(layer);
                    // this.getCurQuiz().container.querySelector('.pq-quiz-buttons-row').appendChild(skippedContainer);

                    layer.addEventListener('click', function (e) {
                        self.stopAudio();
                        layer.remove();
                        self.getCurQuiz().container.removeAttribute('data-skipped');
                        self.getCurQuiz().showAnswerButtons();
                        self.getCurQuiz().unfade();
                        self.changeInstructionLayer("", "");
                    });
                }
            }

            let prevPqType = this.getCurQuiz().pqType;
            let prevGameMode = this.getCurQuiz().gameMode;

            if(this.getCurQuiz().container.hasAttribute('instruction-layer')){
                this.getCurQuiz().removeInstructionLayer();
            }

            if (this.moveBySkipped) {
                let nextIndex = null;
                let nextQuiz = null;

                if (this.curQuizIndex === this.quizzes.length - 1) {
                    nextIndex = 0;
                    nextQuiz = this.getQuiz(nextIndex);
                } else {
                    nextIndex = this.curQuizIndex + 1;
                    nextQuiz = this.getQuiz(nextIndex);
                }

                while (!nextQuiz.container.hasAttribute('data-skipped')) {
                    if (nextIndex >= this.quizzes.length - 1) {
                        // out of bounds, cycle then
                        nextIndex = 0;
                    } else {
                        ++nextIndex;
                    }

                    nextQuiz = this.getQuiz(nextIndex);
                }

                // we found a skipped word
                this.hideQuiz(this.getQuiz(this.curQuizIndex));
                this.curQuizIndex = nextIndex;
            } else {
                this.hideQuiz(this.getQuiz(this.curQuizIndex));
                this.curQuizIndex++;
                //
                // if (this.curQuizIndex === this.quizzes.length - 1) {
                //     this.moveBySkipped = true;
                // }
            }

            this.showQuiz(this.getCurQuiz());

            if (this.getCurQuiz().container.hasAttribute('data-skipped')) {
                this.saySkippedInstruction();
            } else {
                this.changeInstructionLayer(prevPqType, prevGameMode);
            }

            this.enable(this.leftArrow);

            this.changeWordImage(this.getCurQuiz().wordImageUrl);
            this.changeInstructionHeader();
        }
    }

    saySkippedInstruction() {
        this.playHandle = this.player.playText('Click on "skipped" to finish this word.');
    }

    movePrevious() {
        if (this.curQuizIndex > 0) {
            this.skipWarningSwitch = true;
            this.stopAudio();

            if(this.getCurQuiz().container.hasAttribute('instruction-layer')){
                this.getCurQuiz().removeInstructionLayer();
            }

            // interrupt quiz sound
            this.getCurQuiz().stopPlay();

            let prevPqType = this.getCurQuiz().pqType;
            let prevGameMode = this.getCurQuiz().gameMode;

            this.hideQuiz(this.getCurQuiz());
            this.curQuizIndex--;

            this.showQuiz(this.getCurQuiz());

            if (this.getCurQuiz().container.hasAttribute('data-skipped')) {
                this.saySkippedInstruction();
            } else {
                this.changeInstructionLayer(prevPqType, prevGameMode);
            }

            this.enable(this.container.querySelector('.pq-quiz-set-right-arrow'));

            if (this.curQuizIndex === 0) {
                this.disable(this.container.querySelector('.pq-quiz-set-left-arrow'));
            }

            this.changeWordImage(this.getCurQuiz().wordImageUrl);
            this.changeInstructionHeader();
        }
    }

    hideQuiz(quiz) {
        quiz.hide();
    }

    showQuiz(quiz) {
        quiz.show();
    }

    getQuiz(index) {
        return this.quizzes[index];
    }

    setPlayer(player) {
        this.player = player;

        this.quizzes.forEach(function(quiz) {
            quiz.setPlayer(player);
        });
    }

}
